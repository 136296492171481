import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import RootElement from './routes';
import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';

import { environment } from './environments/environment';
import './assets/scss/styles.scss';
import TagManager from "react-gtm-module/dist/TagManager";

const TRACKING_ID = `${environment.TRACKING_ID}`;
const tagManagerArgs = {gtmId: `${environment.GTM_ID}`};

ReactGA.initialize(TRACKING_ID);

if (tagManagerArgs.length > 0) {
  TagManager.initialize(tagManagerArgs)
}

// Sentry.init({
//     beforeSend(event, hint) {
//         if (hint.originalException === "Timeout") return null;
//         return event;
//     },
//     dsn: "https://8b041e2c96ce451ea8f7ce1c12a79f62@o458286.ingest.sentry.io/6502571",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(<RootElement />, document.getElementById('root'));