import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import Swal from 'sweetalert2'
import NumberFormat from "react-number-format";
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col, Form, Button } from "react-bootstrap";
import Loading from '../Loading';
import { environment } from "../../environments/environment";

export default class FormSB extends Component {
    state = {
        verify: [],
        report: [],
        contact: [],
        lead: [],
        checkValues: [],
        formSubmitted: false,
        emailsNotAuthorized: ['geraldoorlando1968@gmail.com'],
        captcha: false,
        load: false
    }

    myChangeHandlerFormVerify = (event) => {
        this.setState({
            verify: {
                ...this.state.verify,
                [event.target.name]: event.target.value
            }
        });
        this.props.parentCallback('');
    }

    myChangeHandlerFormReport = (event) => {
        this.setState({
            report: {
                ...this.state.report,
                [event.target.name]: event.target.value
            }
        });
        this.props.parentCallback('');
    }

    myChangeHandlerFormLeadContact = (event) => {
        if (event.target.name === 'phone') {
            event.target.value = event.target.value.replace(/\D/g, "");
            event.target.value = event.target.value.replace(/^(\d{2})(\d)/g, "($1) $2");
            event.target.value = event.target.value.replace(/(\d)(\d{4})$/, "$1-$2");
            this.setState({
                contact: {
                    ...this.state.contact,
                    [event.target.name]: event.target.value
                }
            });
        } else {
            this.setState({
                contact: {
                    ...this.state.contact,
                    [event.target.name]: event.target.value
                }
            });
        }
    }

    myChangeHandlerFormLead = (event) => {
        if (event.target.name === 'phone') {
            event.target.value = event.target.value.replace(/\D/g, "");
            event.target.value = event.target.value.replace(/^(\d{2})(\d)/g, "($1) $2");
            event.target.value = event.target.value.replace(/(\d)(\d{4})$/, "$1-$2");
            this.setState({
                lead: {
                    ...this.state.lead,
                    [event.target.name]: event.target.value
                }
            });
        } else {
            this.setState({
                lead: {
                    ...this.state.lead,
                    [event.target.name]: event.target.value
                }
            });
        }
    }

    handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            this.setState({
                checkValues: [...this.state.checkValues, value]
            })
        } else {
            this.setState({
                checkValues: this.state.checkValues.filter((item) => item !== value)
            })
        }
    };

    onChangeCaptcha = (event) => {
        this.setState({ captcha: !this.state.captcha })
    }

    verifyUrl = async (event) => {
        event.preventDefault();
        this.setState({ load: true });

        var url = this.state.verify.website
        url = url.toLowerCase();
        const parsedUrl = new URL(url.startsWith("http") ? url : `https://${url}`);
        this.state.verify.website = parsedUrl.hostname

        let oldURL = '';
        if (this.state.verify.website.includes('siteblindado.com')) {
            oldURL = this.state.verify.website;
            this.setState({
                verify: {
                    ...this.state.verify,
                    website: 'wwww.siteblindado.com'
                }
            });
        }
        await axios.post(`${environment.API_LEADS}/consumidor/forms/verifique`, this.state.verify)
            .then(res => {
                this.setState({ load: false });
                res.data.url_inputed = (oldURL !== '' ? oldURL : this.state.verify.website);
                this.props.parentCallback(res.data);
            }).catch(err => {
                this.setState({ load: false });
                let data = {}; data.status = 'error'; data.message = 'Estamos com problemas para encontrar informações sobre esse site. Tente novamente mais tarde.';
                this.props.parentCallback(data);
            });
    }

    reportUrl = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        if (this.state.emailsNotAuthorized.includes(this.state.report.email)) {
            this.setState({ load: false, report: {} });
            swal({ icon: "success", title: "Sucesso!", text: "Obrigado por reportar!" });
            setInterval(() => {
                window.location.reload();
            }, 3000);
            return false;
        }
        if (this.state.report.reason === 'false seal' || this.state.report.reason === 'fake company') {
            await axios.post(`${environment.API_LEADS}/consumidor/forms/denuncie`, this.state.report)
                .then(res => {
                    let data = {}; data.status = 'ok';
                    swal({ icon: "success", title: "Sucesso!", text: "Obrigado por nos ajudar a tornar a internet mais segura!" });
                    this.props.parentCallback(data);
                    this.setState({ load: false, report: {} });
                }).catch(err => {
                    let data = {}; data.status = 'error';
                    this.props.parentCallback(data);
                    swal({ icon: "error", title: "Erro!", text: "Tivemos problemas em enviar sua solicitação. Faça novamente mais tarde." });
                    this.setState({ load: false, report: {} });
                });
        } else {
            this.setState({ load: false, report: {} });
            this.clearForm('form-report');
            if (this.state.report.reason === 'fraud') {
                Swal.fire({
                    width: '50%',
                    html:
                        '<p>A Site Blindado é uma empresa voltada para soluções de segurança de e-commerces e sites institucionais.</p>' +
                        '<p>Nosso canal de denúncia está disponível para receber qualquer denúncia de uso indevido do Selo da Site Blindado em um site.</p>' +
                        '<p>Caso tenha ocorrido algum tipo de fraude com você, lamentamos profundamente esta situação, e recomendamos fortemente que busque os órgãos responsáveis, como Procon e/ou delegacias especializadas para registrar a ocorrência.</p>' +
                        '<p>Abaixo segue link com as dicas de segurança para sempre estar atualizado e evitar cair em golpes na internet: <a href="https://www.siteblindado.com/consumidor/guia-de-seguranca/" target="_blank" rel="noreferrer"><strong>clique aqui</strong></a>.</p>' +
                        '<br><p>Agradecemos a sua compreensão e confiança na marca da Site Blindado.</p>'
                });
            } else {
                Swal.fire({
                    width: '50%',
                    html:
                        '<p>Agradecemos por ajudar a manter a internet mais segura! A Site Blindado não tem autonomia para remover sites do ar, mas indicamos que você realize a denúncia através deste canal do Google: <a href="https://safebrowsing.google.com/safebrowsing/report_phish/" target="_blank" rel="noreferrer"><strong>clique aqui</strong></a>.</p>' +
                        '<p>Outros links úteis:</p> <ul>' +
                        '<li class="text-left"><a href="https://new.safernet.org.br/content/delegacias-cibercrimes" target="_blank" rel="noreferrer"><strong>Delegacias de Cibercrimes</strong></a>;</li>' +
                        '<li class="text-left"><a href="https://www.siteblindado.com/consumidor/guia-de-seguranca/" target="_blank" rel="noreferrer"><strong>Guia de segurança da Site Blindado</strong></a>.</li></ul>'
                });
            }
            return false;
        }
    }

    sendLeadContact = async (event) => {
        event.preventDefault();
        const { contact } = this.state;
        if (contact.documentNumber !== undefined) {
            contact.documentNumber = contact.documentNumber.replace(/\D/g, "");
        }
        this.setState({ load: true });
        if (this.state.emailsNotAuthorized.includes(contact.email)) {
            this.setState({ load: false, contact: {} });
            swal({ icon: "success", title: "Sucesso!", text: "Suas informações foram enviadas com sucesso." });
            setInterval(() => {
                window.location.reload();
            }, 3000);
            return false;
        }
        await axios.post(`${environment.API_LEADS}/consumidor/forms/contato`, contact)
            .then(res => {
                this.setState({ load: false, contact: {} });
                window.location.replace('/sucesso/contato');
            }).catch(err => {
                swal({ icon: "error", title: "Erro!", text: "Tivemos problemas em enviar sua solicitação. Faça novamente mais tarde." });
                this.setState({ load: false });
            });
    }

    sendLead = async (event) => {
        event.preventDefault();

        this.setState({ formSubmitted: true })

        if (this.state.checkValues.length <= 0) {
            return
        }

        const { lead } = this.state;
        if (lead.documentNumber !== undefined) {
            lead.documentNumber = lead.documentNumber.replace(/\D/g, "");
        }
        if (this.props.source) {
            lead.source = this.props.source;
        }
        if (this.state.emailsNotAuthorized.includes(lead.email)) {
            this.setState({ load: false, lead: {} });
            swal({ icon: "success", title: "Sucesso!", text: "Suas informações foram enviadas com sucesso." });
            setInterval(() => {
                window.location.reload();
            }, 3000);
            return false;
        }
        this.setState({ load: true });

        const url = window.location.href;
        const partner = this.props.partner ? this.props.partner : "SITE_BLINDADO"

        const data = { ...lead, origin: partner, products: this.state.checkValues, page_url: url }

        await axios.post(`${environment.API_LEADS}/lead`, data)
            .then(res => {
                this.setState({ load: false, lead: {} });
                window.location.replace('/sucesso/contato');
            }).catch(err => {
                swal({ icon: "error", title: "Erro!", text: "Tivemos problemas em enviar sua solicitação. Faça novamente mais tarde." });
                this.setState({ load: false });
            });
    }

    clearForm = (formName) => {
        document.getElementById(`${formName}`).reset();
        if (document.querySelectorAll(`#${formName} textarea`)[0]) document.querySelectorAll(`#${formName} textarea`)[0].value = '';
    }

    render () {
        const { verify, report, contact, lead } = this.state;
        const recaptchaRef = React.createRef();

        return (
            <>
                {
                    (this.props.type === 'form-verify' &&
                        <Form onSubmit={this.verifyUrl} id="form-verify">
                            <Row className="mb-3">
                                <Col xs={12} md={12}>
                                    <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*URL do seu site</Form.Label>
                                    <Form.Control onChange={this.myChangeHandlerFormVerify} type="text" name="website" required />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12} className="text-center">
                                    <Button className={"btn-form btn-green"} disabled={(verify.website !== undefined && verify.website.length > 4) ? false : true} type="submit">ENVIAR</Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                    || (this.props.type === 'form-report' &&
                        <Form onSubmit={this.reportUrl} id="form-report">
                            <Row className="mb-3">
                                <Col xs={12} md={12}>
                                    <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*URL do site</Form.Label>
                                    <Form.Control type="text" onChange={this.myChangeHandlerFormReport} name="website" defaultValue={report.website} required />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12}>
                                    <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Motivo da denúncia</Form.Label>
                                    <Form.Control as="select" name="reason" onChange={this.myChangeHandlerFormReport} defaultValue={report.reason} required>
                                        <option value=''></option>
                                        {[{ name: "Encontrei um Selo falso da Site Blindado", value: "false seal" }, { name: "Estão se passando pela minha empresa", value: "fake company" }, { name: "Sofri uma fraude", value: "fraud" }, { name: "Encontrei um site com suspeita de golpe", value: "scam" }].map((item, index) => (
                                            <option value={item.value} key={index}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={6}>
                                    <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Nome</Form.Label>
                                    <Form.Control type="text" onChange={this.myChangeHandlerFormReport} name="name" defaultValue={report.name} required />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*E-mail</Form.Label>
                                    <Form.Control type="email" onChange={this.myChangeHandlerFormReport} name="email" defaultValue={report.email} required />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12}>
                                    <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>Mensagem</Form.Label>
                                    <Form.Control as="textarea" onChange={this.myChangeHandlerFormReport} name="message" defaultValue={report.message} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12} className="text-center">
                                    <Button className={"btn-form btn-green"} disabled={Object.keys(report).length > 2 ? false : true} type="submit">ENVIAR</Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                    || (this.props.type === 'form-contact' &&
                        <Row>
                            <Col xs={12} md={6} className="d-flex align-items-center">
                                <Row>
                                    <h3 className={'font-black mb-4 ' + (this.props.color === 'white' ? 'color-dark' : '')}>{this.props.title}</h3>
                                    <h6 className={this.props.color === 'white' ? 'color-dark' : ''}>{this.props.description}</h6>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form onSubmit={this.sendLeadContact}>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Nome</Form.Label>
                                            <Form.Control type="text" onChange={this.myChangeHandlerFormLeadContact} defaultValue={contact.name} name="name" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*E-mail</Form.Label>
                                            <Form.Control type="email" onChange={this.myChangeHandlerFormLeadContact} defaultValue={contact.email} name="email" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Celular</Form.Label>
                                            <NumberFormat className="form-control" format="(##) # ####-####" onChange={this.myChangeHandlerFormLeadContact} defaultValue={contact.cellPhone} name="cellPhone" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Telefone</Form.Label>
                                            <Form.Control type="text" onChange={this.myChangeHandlerFormLeadContact} defaultValue={contact.phone} name="phone" maxLength="15" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*CNPJ</Form.Label>
                                            <NumberFormat className="form-control" format="##.###.###/####-##" onChange={this.myChangeHandlerFormLeadContact} name="documentNumber" defaultValue={contact.documentNumber} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>Mensagem</Form.Label>
                                            <Form.Control as="textarea" onChange={this.myChangeHandlerFormLeadContact} defaultValue={contact.message} name="message" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <ReCAPTCHA className="mt-2" ref={recaptchaRef} sitekey={'6LeNKWMoAAAAAC29tHEhdM2_bwkM94noQiOQ4BUK'} onChange={this.onChangeCaptcha} />
                                        <Col xs={12} md={12} className="text-right mt-md-0 mt-4 text-center-mobile">
                                            <Button className={"btn-form btn-green"} disabled={!this.state.captcha} type="submit">ENVIAR</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    ) || (this.props.type === 'form-client' &&
                        <Row>
                            <Col xs={12} md={6} className="d-flex align-items-center">
                                <Row>
                                    <h3 className={'font-black mb-4 ' + (this.props.color === 'white' && 'color-dark')}>{this.props.title}</h3>
                                    <h6 className={this.props.color === 'white' ? 'color-dark' : ''}>{this.props.description}</h6>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form onSubmit={this.sendLead}>
                                    <Row className="mb-3">
                                        <Col xs={12} md={6}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Nome</Form.Label>
                                            <Form.Control type="text" onChange={this.myChangeHandlerFormLead} defaultValue={lead.name} name="name" required />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*E-mail</Form.Label>
                                            <Form.Control type="email" onChange={this.myChangeHandlerFormLead} defaultValue={lead.email} name="email" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*URL do seu site</Form.Label>
                                            <Form.Control type="text" onChange={this.myChangeHandlerFormLead} defaultValue={lead.website} name="website" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={6}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Celular</Form.Label>
                                            <NumberFormat className="form-control" format="(##) # ####-####" onChange={this.myChangeHandlerFormLead} defaultValue={lead.cellPhone} name="cellPhone" required />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Telefone</Form.Label>
                                            <Form.Control type="text" onChange={this.myChangeHandlerFormLead} defaultValue={lead.phone} name="phone" maxLength="15" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*CNPJ</Form.Label>
                                            <NumberFormat className="form-control" format="##.###.###/####-##" onChange={this.myChangeHandlerFormLead} defaultValue={lead.documentNumber} name="documentNumber" required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>*Produtos</Form.Label>
                                            <Form.Check id="check-blindagem">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    value="blindagem"
                                                    checked={this.state.checkValues.includes("blindagem")}
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <Form.Check.Label className={this.props.color === 'white' ? 'color-dark' : ''}>Blindagem</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check id="check-pentest">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    value="pentest"
                                                    checked={this.state.checkValues.includes("pentest")}
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <Form.Check.Label className={this.props.color === 'white' ? 'color-dark' : ''}>Pentest</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check id="check-waf">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    value="waf"
                                                    checked={this.state.checkValues.includes("waf")}
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <Form.Check.Label className={this.props.color === 'white' ? 'color-dark' : ''}>WAF</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check id="check-ssl">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    value="ssl"
                                                    checked={this.state.checkValues.includes("ssl")}
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <Form.Check.Label className={this.props.color === 'white' ? 'color-dark' : ''}>SSL</Form.Check.Label>
                                            </Form.Check>
                                            {this.state.formSubmitted && this.state.checkValues.length === 0 && (
                                                <Form.Text className={this.props.color === 'white' ? 'color-dark' : 'color-white'}>Selecione ao menos 1 produto.</Form.Text>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={12}>
                                            <Form.Label className={this.props.color === 'white' ? 'color-dark' : ''}>Mensagem</Form.Label>
                                            <Form.Control as="textarea" onChange={this.myChangeHandlerFormLead} defaultValue={lead.message} name="message" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <ReCAPTCHA className="mt-2" ref={recaptchaRef} sitekey={'6LeNKWMoAAAAAC29tHEhdM2_bwkM94noQiOQ4BUK'} onChange={this.onChangeCaptcha} />
                                        <Col xs={12} md={12} className="text-right mt-md-0 mt-4 text-center-mobile">
                                            <Button className={"btn-form btn-green"} disabled={!this.state.captcha} type="submit">ENVIAR</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    )
                }
                <Loading show={this.state.load} />
            </>
        );
    }
}
