import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import ArrowButton from "../ArrowButton";
import { formatBody } from "../../services/utils";

const CONTENTFUL_TOKEN = process.env.REACT_APP_CONTENTFUL_TOKEN;

export default class BlogNews extends Component {
    state = {
        posts: [],
    }

    componentDidMount = async () => {
        try {
            const res = await axios.get("https://preview.contentful.com/spaces/a9mooz6ri0vn/environments/master/entries", {
                params: {
                    access_token: CONTENTFUL_TOKEN,
                    content_type: "article",
                    limit: 3,
                    order: "-fields.date",
                },
            });

            const items = res.data.items;
            const requests = items.map(async (item) => {
                const [categoryRes, imgRes, authorRes] = await Promise.all([
                    axios.get(`https://preview.contentful.com/spaces/a9mooz6ri0vn/environments/master/entries/${item.fields.category.sys.id}`, { headers: { Authorization: `Bearer qmIhavSPWNv5dkAk38BZVmMBNdUbhsDmlIDEnlQl2vM` } }),
                    axios.get(`https://preview.contentful.com/spaces/a9mooz6ri0vn/environments/master/assets/${item.fields.heroImage.sys.id}`, { headers: { Authorization: `Bearer qmIhavSPWNv5dkAk38BZVmMBNdUbhsDmlIDEnlQl2vM` } }),
                    axios.get(`https://preview.contentful.com/spaces/a9mooz6ri0vn/environments/master/entries/${item.fields.author.sys.id}`, { headers: { Authorization: `Bearer qmIhavSPWNv5dkAk38BZVmMBNdUbhsDmlIDEnlQl2vM` } }),
                ]);

                const category = categoryRes.data.fields.name;
                const img = "https:" + imgRes.data.fields.file.url;
                const author = authorRes.data.fields.name;

                return {
                    ...item,
                    category,
                    image: img,
                    author,
                };
            });

            const processedPosts = await Promise.all(requests);

            this.setState({ posts: processedPosts });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { posts } = this.state;

        return (
            <Col xs={12} md={12}>
                <Row>
                    <Col xs={12} md={12}>
                        <h3 className="font-medium text-dark text-center text-uppercase">Últimas notícias</h3>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {posts.map((post, index) => (
                        <Col xs={12} md={4} key={index} className="mb-4">
                            <Card className="card-blog-news">
                                <Row>
                                    <Col xs={12} md={12}>
                                        <a href={`/blog/single-post/${post.fields.slug}`} target="_blank" rel="noreferrer"><img src={post.image} className="img-fluid img-stretch img-blog-card" alt="" /></a>
                                    </Col>
                                </Row>
                                <Row className="h-100">
                                    <Col xs={12} md={12} className="description-news">
                                        <Row className="h-100">
                                            <small className="color-gray">@{post.author}</small>
                                            <Col xs={6}>
                                                <p className="font-semi-bold text-left text-uppercase">{post.category}</p>
                                            </Col>
                                            <Col xs={6}>
                                                <p className="font-light color-gray text-right">{new Date(post.fields.date).toLocaleDateString("pt-BR", "DD/MM/YYYY")}</p>
                                            </Col>
                                            <h5 className="font-semi-bold color-dark mb-5">{post.fields.title}</h5>
                                            <p className="font-medium mb-4">{formatBody(post.fields.body)}</p>
                                            <div className="flex-end">
                                                <ArrowButton link={`/blog/single-post/${post.fields.slug}`} newTab={true} type="black" side="left" text="LEIA MAIS" />
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Col>
        );
    }
}
