import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormSB from "../../../components/Form";
import Layout from "../../../components/Layout";
import SSLDv from "../../../assets/img/products/ssl-dv.png"
import SSLOv from "../../../assets/img/products/ssl-ov.png"
import SSLEv from "../../../assets/img/products/ssl-ev.png"

import { PRODUCTS } from "../../../constants/products"

export default class SSL extends Component {
    state = {
        data: [],
        description: 'O certificado SSL protege os dados sensíveis dos usuários que navegam em seu site através da criptografia. Conheça os diferenciais do SSL da Site blindado.'
    }

    componentDidMount () {
        document.title = "Certificado SSL – Site Blindado";
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', this.state.description ? this.state.description : '');
        };
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        const { data } = this.state;

        const handlePurchase = (productId) => {
            localStorage.removeItem("product");
            localStorage.setItem("product", productId);
            window.location.href = "/produtos/ssl/shop";
        }

        return (
            <Layout>
                <div className="section-height-100-center products-sess1">
                    <Row className="h-100 justify-content-center align-items-center">
                        <Col xs={12} md={6} className="d-lg-block d-none">
                            <h1 className="title-products font-black title-outline-green text-right">SSL.SSL.</h1>
                            <h1 className="title-products font-black text-right">SSL.SSL.</h1>
                            <h1 className="title-products font-black title-outline-green text-right">SSL.SSL.</h1>
                        </Col>
                        <Col xs={12} md={6} className="description-product-sess1">
                            <h1 className="d-md-none d-block title-products font-black title-outline-green">SSL.SSL.</h1>
                            <h3 className="font-semi-bold mb-4">Certificado SSL</h3>
                            <h6>O <strong>Certificado SSL</strong> é uma tecnologia de criptografia essencial para a proteção de dados sensíveis. Além disso, é um dos parâmetros de indexação do motor de buscas do Google.</h6>
                        </Col>
                    </Row>
                </div>
                <div className="padding-container">
                    {/* <Card className="bg-dark-green pentest-info d-none d-lg-block">
                        <h3 className="text-center">
                            <strong>Escolha e adquira agora mesmo o certificado SSL ideal para proteção do seu site!</strong>
                        </h3>
                    </Card>
                    <Row className="padding-row text-center justify-content-center aling-items-center">
                        <Col md={8}>
                            <h4 className="font-black color-dark">Para adquirir nossos produtos personalizados com suporte técnico, fale com nossa equipe de vendas                                 <a style={{ textDecoration: "none" }} href="#form">
                                <span className="color-gradient">clicando aqui</span>
                            </a> e aproveite!
                            </h4>
                        </Col>
                    </Row>
                    <Row xs={1} sm={1} md={1} lg={2} xl={3} style={{ paddingTop: "3rem" }} className="padding-row px-md-5 mx-md-5 justify-content-start">
                        {PRODUCTS.map((product) => (
                            <Col key={product.id} className="mb-4">
                                <Card border="success" className="h-100" style={{ boxShadow: "0px 1px 15px -10px" }}>
                                    <Card.Header className="bg-dark-green text-center" style={{ color: 'white' }}>
                                        <h4 className="m-3"><strong>{product.name}</strong></h4>
                                    </Card.Header>
                                    <Card.Body style={{ padding: "2rem", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <Row>
                                            <div className="px-md-3">
                                                <p><strong>{product.label}</strong></p>
                                                <p className="text-justify">{product.description}</p>
                                            </div>
                                        </Row>
                                        <div className="d-flex flex-column justify-content-end">
                                            <Row style={{ marginTop: "2rem", marginBottom: "1rem" }} xs={12} md={12}>
                                                <Col xs={12} md={12} className="d-flex justify-content-end align-items-center">
                                                    <div className="text-start">
                                                        <small><strong>A partir de:</strong></small>
                                                        <h3 style={{ color: 'black', marginBottom: "0px" }}><strong>R$ {product.price.toFixed(2)}</strong></h3>
                                                        <sup><strong>{product.validTime}</strong></sup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row xs={12} md={12} className="align-items-center">
                                                <Col>
                                                    <div className="d-grid gap-2">
                                                        <Button onClick={() => handlePurchase(product.id)} className={"btn-green-dark"} style={{ color: "#000", fontSize: 20 }}><strong>Comprar</strong></Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row> */}

                    <Container>
                        <Row className="justify-content-center align-items-center mt-5 mb-5">
                            <Col xs={12} md={12}>
                                <h3 className="color-dark text-center font-bold">Um certificado para cada modelo de negócio</h3>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={12} md={12}>
                                <Row className="align-items-center mb-md-0 mb-4">
                                    <Col xs={12} md={6}>
                                        <img src={SSLDv} className="img-fluid d-lg-block d-none" alt="" />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <h3 className="color-secondary font-semi-bold mb-md-5 mb-2">SSL SD (SIMPLES)</h3>
                                        <h6 className="color-dark font-light">Precisa da certificação para apenas uma URL? Esse é o modelo ideal para a sua empresa.</h6>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-md-0 mb-4">
                                    <Col xs={12} md={6}>
                                        <h3 className="color-secondary font-semi-bold mb-md-5 mb-2">SSL MDC</h3>
                                        <h6 className="color-dark font-light">Necessita que o certificado SSL cubra múltiplos FQDNs? O Modelo SSL MDC atende até 200*.</h6>
                                        <small>* 3 por padrão. As demais precisam ser compradas a parte até o limite de 200 por certificado.</small>
                                    </Col>
                                    <Col xs={12} md={6} className="text-right d-flex justify-content-end">
                                        <img src={SSLOv} className="img-fluid d-lg-block d-none" alt="" />
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-md-0 mb-4">
                                    <Col xs={12} md={6}>
                                        <img src={SSLEv} className="img-fluid d-lg-block d-none" alt="" />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <h3 className="color-secondary font-semi-bold mb-md-5 mb-2">SSL WILDCARD</h3>
                                        <h6 className="color-dark font-light">Acompanhe os Scans pela plataforma da Site Blindado através de um modelo que comporta subdomínios ilimitados. </h6>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-md-0 mb-4">
                                    <Col xs={12} md={6}>
                                        <h3 className="color-secondary font-semi-bold mb-md-5 mb-2">SSL SAN UCC</h3>
                                        <h6 className="color-dark font-light">Inclui até 200 FQDNs e atende aplicações como Microsoft Exchange, Office Comunication, Lync Server e muito mais.</h6>
                                    </Col>
                                    <Col xs={12} md={6} className="text-right d-flex justify-content-end">
                                        <img src={SSLDv} className="img-fluid d-lg-block d-none" alt="" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-center align-items-center mb-5">
                            <Col xs={12} md={12}>
                                <h3 className="color-dark text-center font-bold">Mais certificados? A Site Blindado tem.</h3>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={12} md={12}>
                                <Row className="align-items-center mb-md-0 mb-4">
                                    <Col xs={12} md={6}>
                                        <img src={SSLOv} className="img-fluid d-lg-block d-none" alt="" />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <h3 className="color-secondary font-semi-bold mb-md-5 mb-2">CodeSign</h3>
                                        <h6 className="color-dark font-light">Ateste que foi a sua empresa que desenvolveu o código-fonte da aplicação, permitindo que os desenvolvedores de software possam assiná-lo digitalmente.</h6>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-md-0 mb-4">
                                    <Col xs={12} md={6}>
                                        <h3 className="color-secondary font-semi-bold mb-md-5 mb-2">s/mine</h3>
                                        <h6 className="color-dark font-light">Garanta a autenticidade de origem dos seus e-mails.</h6>
                                    </Col>
                                    <Col xs={12} md={6} className="text-right d-flex justify-content-end">
                                        <img src={SSLEv} className="img-fluid d-lg-block d-none" alt="" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col xs={12} md={6} className="lock-bg">
                                <h3 className="color-dark text-center">Se a sua empresa precisa da certificação para apenas uma URL,</h3>
                                <h2 className="color-primary font-bold text-center">esse modelo de negócio é ideal para você.</h2>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row className="align-items-center mb-4">
                                    <Col xs={2} md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <p className="m-0"><strong>Suporte em português;</strong></p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col xs={2} md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <p className="m-0"><strong>Scan SSL;</strong></p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col xs={2} md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <p className="m-0"><strong>Configuração e instalação em múltiplos servidores sem custo adicional;</strong></p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col xs={2} md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <p className="m-0"><strong>Validação para os certificados DV, OV e EV.</strong></p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="form" className={`padding-container bg-dark-green ${(data.status !== undefined ? ((data.status === 'error' ? 'bg-dark-red' : '') || (data.status === 'ok' ? 'bg-dark-blue' : '')) : '')}`}>
                    <Container>
                        <FormSB type="form-client" parentCallback={this.callbackFunction} source="[PRODUTO] SSL" title="Quer saber mais sobre o Certificado SSL?" description={<>Preencha o formulário a seguir para que a nossa equipe de especialistas possa entrar em contato e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> que atenda às suas necessidades.</>} />
                    </Container>
                </div>
            </Layout >
        );
    }
}
