import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import FormSB from "../../../components/Form";
import ArrowButton from "../../../components/ArrowButton";
import Layout from "../../../components/Layout";
import Shield from "../../../assets/img/products/blindagem.jpg";

export default class Blindagem extends Component {
    state = {
        data: [],
        description: 'A Blindagem de Sites é um serviço de auditoria, oferece uma validação de segurança digital automatizada realizada por scans de vulnerabilidades que buscam e catalogam brechas no sistema.'
    }

    componentDidMount() {
        document.title = "Blindagem de Sites – Site Blindado";
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', this.state.description ? this.state.description : '');
        };
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render() {
        const { data } = this.state;

        return (
            <Layout>
                <div className="section-height-100-center products-sess1">
                    <Row className="h-100 justify-content-center align-items-center">
                        <Col xs={12} md={6} className="d-lg-block d-none">
                            <h1 className="title-products font-black title-outline-green text-right">BLINDAGEM.</h1>
                            <h1 className="title-products font-black text-right">BLINDAGEM.</h1>
                            <h1 className="title-products font-black title-outline-green text-right">BLINDAGEM.</h1>
                        </Col>
                        <Col xs={12} md={6} className="description-product-sess1">
                            <h1 className="d-md-none d-block title-products font-black title-outline-green">BLINDAGEM.</h1>
                            <h3 className="font-semi-bold mb-4">Blindagem de sites</h3>
                            <h6>A <strong>Blindagem de Sites</strong> atua em conjunto com a sua equipe de TI oferecendo uma validação de segurança digital automatizada, realizada por Scans de Vulnerabilidades que buscam, analisam e catalogam brechas presentes no sistema.</h6>
                        </Col>
                    </Row>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={6}>
                                <h3 className="color-primary font-bold mb-5">Selo Site Blindado</h3>
                                <h4 className="color-dark font-light mb-5">Após corrigidas as falhas encontradas por nossos Scans de Vulnerabilidades, será possível exibir o Selo Site Blindado, uma <span className="font-semi-bold">referência de segurança online</span> que contribui para a construção da credibilidade da sua empresa.</h4>
                                <ArrowButton link="/produtos/blindagem#form" type="black" side="left" text="Quero ser blindado" />
                            </Col>
                            <Col xs={12} md={6} className="text-center d-lg-block d-none">
                                <img src={Shield} className="img-fluid" alt="" style={{ maxHeight: 400 }} />
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={6} className="mb-md-0 mb-4">
                                <Card className="card-products">
                                    <h3 className="font-light color-dark mb-5">Blindagem Simples</h3>
                                    <h6 className="font-light color-dark mb-4">Indicada para empresas com baixo risco de segurança ou em início de operação.</h6>
                                    <p className="mt-2">· Testes automatizados de vulnerabilidades de código em sites e servidores; <br />
                                        · Selo Site Blindado; <br />
                                        · SSL Simples.
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="card-products">
                                    <h3 className="font-light color-dark mb-5">Blindagem Premium</h3>
                                    <h6 className="font-light color-dark mb-4">Ideal para empresas mais robustas. Conta com todas as funcionalidades da Blindagem Simples, além de outros recursos:</h6>
                                    <p className="mt-2">· Testes automatizados de vulnerabilidades em sites e servidores para até 2 URLs; <br />
                                        · Selo Site Blindado; <br />
                                        · SSL wildcard ou ev; <br />
                                        · Quick pentest de aplicação.
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="form" className={`padding-container bg-dark-green ${(data.status !== undefined ? (data.status === 'error' ? 'bg-dark-red' : '') || (data.status === 'ok' ? 'bg-dark-blue' : '') : '')}`}>
                    <Container>
                        <FormSB type="form-client" parentCallback={this.callbackFunction} source="[PRODUTO] Blindagem" title="Quer saber mais sobre Blindagem?" description={<>Preencha o formulário a seguir para que a nossa equipe de especialistas possa entrar em contato e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> que atenda às suas necessidades.</>} />
                    </Container>
                </div>
            </Layout>
        );
    }
}