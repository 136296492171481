import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form"
import NumberFormat from "react-number-format";

import axios from "axios";
import swal from "sweetalert";


import { PRODUCTS } from "../../../constants/products"
import Layout from '../../../components/Layout';
import Loading from '../../../components/Loading';
import { environment } from "../../../environments/environment";


function Shop () {
    const DEFAULT_FQDN = 3;
    const { handleSubmit, control, resetField, formState: { errors } } = useForm()
    const [load, setLoad] = useState(false)
    const [product, setProduct] = useState({})
    const [person, setPerson] = useState("fisica")

    const [enableBtn, setEnableBtn] = useState(false)
    const [contractChecked, setContractChecked] = useState(false)
    const [privacyChecked, setPrivacyChecked] = useState(false)
    const [creditQtd, setCreditQtd] = useState(1)
    const [fqdmQtd, setFqdnQtd] = useState(3)
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        const productId = localStorage.getItem("product");
        const productFilter = PRODUCTS.filter(product => (product.id === productId));
        setProduct(productFilter[0]);
        setTotalPrice(productFilter[0].price.toFixed(2));
    }, [])

    const calculateTotalPrice = (credit, fqdn) => {
        let orderPrice = product.price * credit;
        if (product.fqdn && fqdn > DEFAULT_FQDN) {
            const numberFqdn = (fqdn - DEFAULT_FQDN) * credit
            const fqdnAmount = product.fqdnPrice * numberFqdn;
            orderPrice += fqdnAmount;
        }
        setTotalPrice(orderPrice.toFixed(2));
    }

    const handleCreditsQtd = (e) => {
        setCreditQtd(e.target.value)
        calculateTotalPrice(e.target.value, fqdmQtd)
    }

    const handleFqdnQtd = (e) => {
        setFqdnQtd(e.target.value)
        calculateTotalPrice(creditQtd, e.target.value)
    }

    const handleEnablePurchase = (termC, termP) => {
        setEnableBtn(termC && termP);
    }

    const handleContractChecked = (e) => {
        setContractChecked(e.target.checked)
        handleEnablePurchase(e.target.checked, privacyChecked)
    }

    const handlePrivacyChecked = (e) => {
        setPrivacyChecked(e.target.checked)
        handleEnablePurchase(contractChecked, e.target.checked)
    }


    const handlePersonChange = (e) => {
        if (e.target.value === "fisica") {
            resetField("organization_legal_name");
            resetField("organization_document_number");
            resetField("organization_job_position");
        }
        setPerson(e.target.value);
    }

    const onSubmit = async (formData) => {
        setLoad(true);
        const productData = {
            product_id: product.id,
            quantity: parseInt(creditQtd),
            fqdn_quantity: product.fqdn ? parseInt(fqdmQtd) : 1
        }
        const data = {
            customer: {...formData.customer},
            payment: {
                billing_address: { ...formData.billing }
            },
            products: [productData],
            privacy_signed: privacyChecked,
            contract_signed: contractChecked
        };
        await axios.post(`${environment.SB_API}/v1/orders`, data)
            .then(res => {
                const { data } = res;
                setLoad(false)
                localStorage.removeItem("product_order");
                localStorage.setItem("product_order", data.id);
                window.location.replace("/obrigado");
            }).catch(err => {
                swal({ icon: "error", title: "Erro!", text: "Tivemos problemas em enviar sua solicitação. Entre em contato com nossa equipe de suporte." });
                setLoad(false)
            });
    }

    return (
        <>
            <Layout>
                <div className='padding-container mobile-height-100 bg-dark-green'>
                    <Container>
                        <Row className='align-items-center'>
                            <Col xs={12} md={8}>
                                <h1 className='font-black mb-0'>
                                    Finalizar Compra
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='padding-container'>
                    <Container className='self-checkout'>
                        <Row>
                            <Col xs={12} md={12} lg={7} className='pe-lg-5'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <h3 className='font-bold color-dark mb-3'>Dados da Compra</h3>
                                    </Row>
                                    <Row>
                                        <div key={`inline-radio`} className="mb-3">
                                            <span style={{marginRight: '1rem'}}>Comprar como:</span>
                                            <Form.Check
                                                inline
                                                id={`inline-radio-1`}
                                                name="group1"
                                                label="Pessoa Física"
                                                type="radio"
                                                value="fisica"
                                                style={{color: 'black !important'}}
                                                checked={person === "fisica"}
                                                onChange={handlePersonChange}
                                            />
                                            <Form.Check
                                                inline
                                                id={`inline-radio-2`}
                                                name="group1"
                                                type="radio"
                                                label="Pessoa Juridíca"
                                                value="juridica"
                                                className='color-dark'
                                                checked={person === "juridica"}
                                                onChange={handlePersonChange}
                                            />
                                        </div>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="customer.first_name"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*Nome</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="customer.sur_name"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label
                                                                    className='color-dark'>*Sobrenome</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.sur_name && <Form.Text className="text-danger">Campo
                                                                    Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="customer.email"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: true,
                                                            pattern: /^\S+@\S+$/i
                                                        }} // Email pattern validation
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*Email</Form.Label>
                                                                <Form.Control type="email" {...field} />
                                                                {errors.email && errors.email.type === "required" && (
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>
                                                                )}
                                                                {errors.email && errors.email.type === "pattern" && (
                                                                    <Form.Text className="text-danger">Insira um email
                                                                        valido.</Form.Text>
                                                                )}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="customer.phone"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true, maxLength: 15}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label
                                                                    className='color-dark'>*Telefone</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.phone && errors.phone.type === "required" && (
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>
                                                                )}
                                                                {errors.phone && errors.phone.type === "maxLength" && (
                                                                    <Form.Text className="text-danger">Informe um número
                                                                        valido.</Form.Text>
                                                                )}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col xs={12} md={12}>
                                                    <Controller
                                                        name="customer.document_number"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*CPF</Form.Label>
                                                                <NumberFormat className="form-control"
                                                                              format="###.###.###-##" {...field} />
                                                                {errors.document_number &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>

                                            {person === "juridica" && (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col xs={12} md={12}>
                                                            <Controller
                                                                name="customer.organization_legal_name"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{required: true}}
                                                                render={({field}) => (
                                                                    <Form.Group>
                                                                        <Form.Label className='color-dark'>*Razão
                                                                            Social</Form.Label>
                                                                        <Form.Control {...field} />
                                                                        {errors.organization_legal_name &&
                                                                            <Form.Text className="text-danger">Campo
                                                                                Obrigatório.</Form.Text>}
                                                                    </Form.Group>
                                                                )}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xs={12} md={12}>
                                                            <Controller
                                                                name="customer.organization_document_number"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{required: true}}
                                                                render={({field}) => (
                                                                    <Form.Group>
                                                                        <Form.Label
                                                                            className='color-dark'>*CNPJ</Form.Label>
                                                                        <NumberFormat className="form-control"
                                                                                      format="##.###.###/####-##" {...field} />
                                                                        {errors.organization_document_number &&
                                                                            <Form.Text className="text-danger">Campo
                                                                                Obrigatório.</Form.Text>}
                                                                    </Form.Group>
                                                                )}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xs={12} md={12}>
                                                            <Controller
                                                                name="customer.organization_job_position"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{required: true}}
                                                                render={({field}) => (
                                                                    <Form.Group>
                                                                        <Form.Label
                                                                            className='color-dark'>*Cargo</Form.Label>
                                                                        <Form.Select {...field}>
                                                                            <option>Selecione</option>
                                                                            <option
                                                                                value="Comercial/Marketing">Comercial/Marketing
                                                                            </option>
                                                                            <option value="Técnico">Técnico</option>
                                                                            <option value="Financeiro">Financeiro
                                                                            </option>
                                                                            <option value="Proprietário">Proprietário
                                                                            </option>
                                                                            <option value="Outro">Outro</option>
                                                                        </Form.Select>
                                                                        {errors.organization_job_position &&
                                                                            <Form.Text className="text-danger">Campo
                                                                                Obrigatório.</Form.Text>}
                                                                    </Form.Group>
                                                                )}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "4rem"}}>
                                        <h3 className='font-bold color-dark mb-3'>Endereço de Cobrança</h3>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col xs={12} md={12}>
                                                    <Controller
                                                        name="billing.street"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*Rua</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={12} md={12}>
                                                    <Controller
                                                        name="billing.complement"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label
                                                                    className='color-dark'>*Complemento</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="billing.number"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*Número</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="billing.zip_code"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*CEP</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="billing.city"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*Cidade</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="billing.state"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*Estado</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={12} md={6}>
                                                    <Controller
                                                        name="billing.country"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{required: true}}
                                                        render={({field}) => (
                                                            <Form.Group>
                                                                <Form.Label className='color-dark'>*País</Form.Label>
                                                                <Form.Control {...field} />
                                                                {errors.first_name &&
                                                                    <Form.Text className="text-danger">Campo
                                                                        Obrigatório.</Form.Text>}
                                                            </Form.Group>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                            <Col xs={12} md={12} lg={5}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Card border="success">
                                            <Card.Header className="bg-dark-green text-center" style={{ color: 'white' }}>
                                                <h3 className="m-3"><strong>Detalhes do Pedido</strong></h3>
                                            </Card.Header>
                                            <Card.Body style={{ marginTop: "1rem", paddingLeft: "2rem", paddingRight: "2rem" }}>
                                                <h4 className='font-bold color-dark mb-3'>{product.name}</h4>
                                                <Row>
                                                    <Col>
                                                        <p><strong>Termo de adesão</strong></p>
                                                        <p className="contract text-justify">
                                                            Este Termo de Adesão se refere à aquisição de certificado digital Secure Socker Layer (“Certificado Digital SSL”), oferecido pela BIT SERVICES INOVAÇÃO E TECNOLOGIA LTDA, com sede na Rua Sacadura Cabral, 126, Parte, Saúde, Rio de Janeiro/RJ, inscrita no CNPJ sob o nº 00.776.574/0006-60
                                                        </p>
                                                        <p className="contract text-justify">O presente Termo de Adesão é vinculado às condições previstas no Contrato Padrão de Cessão de Uso de Software Via Internet (“Contrato”) disponível no link <a href="https://www.siteblindado.com/consumidor/contratos-de-servicos" target="_blank">https://www.siteblindado.com/consumidor/contratos-de-servicos/</a>.
                                                        </p>
                                                        <p className="contract text-justify">
                                                            <b>Ao aceitar este Termo de Adesão o Cliente está automaticamente concordando e aderindo com os termos do Contrato.</b>
                                                        </p>
                                                        <p className="contract text-justify">
                                                            Após a Contratação, com o respectivo pagamento, não é possível realizar o cancelamento.
                                                        </p>

                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`contrato-check`}
                                                            label="Aceito o Termos de Adesão e Contrato de prestação de Serviços"
                                                            checked={contractChecked}
                                                            onChange={handleContractChecked}
                                                        />

                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`check-api-checkbox`}
                                                            checked={privacyChecked}
                                                        >
                                                            <Form.Check.Input
                                                                type="checkbox"
                                                                onChange={handlePrivacyChecked}
                                                            />
                                                            <Form.Check.Label>Aceito o <a href="/consumidor/politica-de-privacidade" target='_blank'>termo de privacidade</a></Form.Check.Label>
                                                        </Form.Check>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "3rem", marginBottom: "2rem" }} xs={12} md={12}>
                                                    <Col xs={12} md={5}>
                                                        <Row>
                                                            <Col>
                                                                <sub><strong>Quantidade de Créditos</strong></sub>
                                                                <Form.Control type="number" defaultValue="1" min={1}
                                                                              onChange={handleCreditsQtd}/>
                                                                <sub style={{ fontSize: "0.6rem", fontWeight: 100 }}><strong>{product.validTime}</strong></sub>
                                                            </Col>
                                                        </Row>
                                                        {product.fqdn && (
                                                            <Row style={{ marginTop: "1rem" }}>
                                                                <Col>
                                                                    <sub><strong>Quantidade de FQDNs</strong></sub>
                                                                    <Form.Control type="number" defaultValue="3" min={3} onChange={handleFqdnQtd} />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        {!product.fqdn && (
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="hidden" defaultValue="1" min={1} onChange={handleFqdnQtd} />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                    <Col xs={12} md={7} className='d-flex justify-content-center align-items-center'>
                                                        <div className="text-end">
                                                            <h3 style={{color: 'black', marginBottom: 0}}>
                                                                <strong>R$ {totalPrice}</strong></h3>
                                                            <sub style={{
                                                                color: "#777",
                                                                fontSize: "1rem",
                                                                fontWeight: 100
                                                            }}><strong>À vista, no Boleto</strong></sub>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row xs={12} md={12} className="align-items-center">
                                                    <Col>
                                                        <div className="d-grid gap-2">
                                                            <Button
                                                                onClick={handleSubmit(onSubmit)}
                                                                className={"btn-green-dark"}
                                                                disabled={!enableBtn}
                                                                style={{
                                                                    color: "#000",
                                                                    fontSize: 20,
                                                                    marginBottom: "1rem"
                                                                }}
                                                            >
                                                                <strong>Finalizar Compra</strong>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </Layout >
            <Loading show={load} />
        </>
    )
}
export default Shop
